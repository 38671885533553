export const getLocale = (): string => {
  const supportedLanguages = ["en-US", "fr-FR"];

  let locale: string =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    "fr-FR";

  locale = locale === "fr" ? "fr-FR" : locale;

  if (!supportedLanguages.find((l) => l === locale)) {
    locale = "fr-FR";
  }

  if (localStorage.getItem("language")) {
    locale = localStorage.getItem("language") || "";
  }
  return locale;
};

export const setPriceLocale = (
  price: any,
  noFractionDigit?: boolean,
): string => {
  const supportedLanguages = ["en-US", "fr-FR"];
  let locale =
    localStorage.getItem("language") || navigator.language || "fr-FR";

  if (locale.startsWith("fr")) locale = "fr-FR";
  if (!supportedLanguages.includes(locale)) locale = "fr-FR";

  const currency = locale === "fr-FR" ? "EUR" : "USD";
  const hasDecimal = price % 1 !== 0;

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: noFractionDigit && !hasDecimal ? 0 : 2,
    maximumFractionDigits: 2,
  }).format(price);
};
