import { setPriceLocale } from "../services/Locale";
import { arrondi } from "./common";

export const getFormattedAmount = (
  value: number | null,
  noFractionDigit?: boolean,
) =>
  value != undefined && value != null && !isNaN(value)
    ? setPriceLocale(arrondi(value), noFractionDigit)
    : "-";
